import MessagePic from "./MessagePic.jsx";
import UserMessage from "./UserMessage.jsx";
import AssistantMessage from "./AssistantMessage.jsx";


const ChatMessage = ({ index, message, writing, setVersionsOpen, handleOptionClick }) => {

  const { id, createdAt, content, role, documents, metadatas } = message;


  return (
    <div
      className={`${role === "system" ? "flex-row-reverse bg-light-white px-4 w-full" : "px-4 w-full"} message md:min-w-[640px]`}
    >
      {role === "system" ? (
        <AssistantMessage message_id={id} index={index} documents={documents} content={content} createdAt={createdAt}
          setVersionsOpen={setVersionsOpen} writing={writing} metadatas={metadatas} handleOptionClick={handleOptionClick}
        />
      ) : (
        <>
          <UserMessage message_id={id} index={index} content={content} createdAt={createdAt} />
        </>
      )}


      <MessagePic role={role} writing={writing} />
    </div>
  );
};
export default ChatMessage;
